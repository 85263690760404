import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import BaseLayout from '../layouts/BaseLayout';
import Terms from '../components/pages/terms';

const TermsPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <BaseLayout className="terms">
            <Helmet>
                <title>The House Of | Terms</title>
            </Helmet>
            <Terms/>
        </BaseLayout>
    )
}

export default TermsPage;
