import React, {useEffect, useState} from 'react'
import {Link} from 'gatsby'
import SafariDetect from '../components/elements/SafariDetect'
import Image from '../components/elements/Image'
import Logo from '../components/svg/Logo'
import Door from '../components/svg/Door'
import HeaderLinks from '../jsons/header.json'
import FooterLinks from '../jsons/footer.json'

const Header = () => {
    const [headerClass, setHeaderClass] = useState(false)
    const [open, setOpen] = useState(false)
    const [main, setMain] = useState(false)

    useEffect(() => {
        document.addEventListener(
            'section-changed',
            () => {
                setOpen(false)
            },
            false
        )

        if (document.querySelector('.bright-header')) {
            if (document.querySelector('main').classList.contains('bright-header')) {
                setMain(true)
            }
        }

        const header = document.querySelector('header')

        if (header.classList.contains('open-menu')) {
            document.body.classList.add('dis-scroll')
        } else {
            document.body.classList.remove('dis-scroll')
        }

        return () =>
            document.removeEventListener('section-changed', () => setOpen(), false)
    }, [])

    useEffect(() => {
        const wwdPage = document.getElementById('whatWeDoMain')

        const scrollCheck = () => {
            const elements = document.querySelectorAll('.dark-section')
            const secondaryDark = document.querySelector('.darker-section')
            if (wwdPage) {
                if (
                    0 >= elements[0].getBoundingClientRect().top &&
                    elements[0].getBoundingClientRect().height +
                    elements[0].getBoundingClientRect().top >=
                    0 || 0 >= secondaryDark.getBoundingClientRect().top &&
                    secondaryDark.getBoundingClientRect().height +
                    secondaryDark.getBoundingClientRect().top >= 0
                ) {
                    setHeaderClass(true)
                } else {
                    setHeaderClass(false)
                }
            } else {
                elements.forEach(elem => {
                    if (
                        0 >= elem.getBoundingClientRect().top &&
                        elem.getBoundingClientRect().height +
                        elem.getBoundingClientRect().top >= 0
                    ) {
                        setHeaderClass(true)
                    } else {
                        setHeaderClass(false)
                    }
                })
            }
        }
        window.addEventListener('scroll', scrollCheck)
        return () => window.removeEventListener('scroll', scrollCheck)
    },[])


    const classNames = () => {
        return `header${main || headerClass ? ' header__dark' : ''}${open ? ' header__open' : ''}`
    }

    return (
        <header className={classNames()}>
            {open && <div
                className="header__background"
                id="background"
                onClick={() => setOpen(false)}
            />}
            <div className="logo-door">
                <div className="logo">
                    <Link to="/">
                        <Logo/>
                    </Link>
                </div>
                <div
                    role="presentation"
                    className={`logo-door-icon${open ? ' vis-hidden' : ''}`}
                    onKeyDown={() => setOpen(true)}
                    onClick={() => setOpen(true)}
                >
                    <Door role="presentation"/>
                </div>
            </div>
            <nav className="nav">
                <div className="nav__container">
                    <div className="nav__logo">
                        <Link to="/">
                            <Logo/>
                        </Link>
                        <span onKeyDown={() => setOpen(false)} onClick={() => setOpen(false)}>
                            <Image
                                src="/svgs/close-white.svg"
                                alt="icon"
                                className="nav__close"
                                role="presentation"
                            />
                        </span>
                    </div>
                    <SafariDetect>
                        <ul className="menu__list">
                            {HeaderLinks.headerLinks.map(item => (
                                <li key={item.title} onClick={() => setOpen(false)}>
                                    <Link to={item.link} exit={{length: 1}}>
                                        {item.title}
                                    </Link>
                                </li>
                            ))}
                            <li>
                                {FooterLinks.footerSocials.map((item, index) => {
                                    return (
                                        <a href={item.link} target="_blank" rel="noreferrer"
                                           key={`social-${index}`}>
                                            <Image src={item.icon} alt={item.alt}/>
                                        </a>
                                    )
                                })}
                            </li>
                        </ul>
                    </SafariDetect>
                </div>
            </nav>
        </header>
    )
}

export default Header
