import React from 'react'

const Door = props => {
  return (
    <>
        <svg {...props} width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_4_2)">
                <path d="M5.19597 0.262766L4.86694 0.525532L4.82581 18.7947L4.79839 37.0638H2.70081C0.315323 37.0638 0 37.1606 0 37.9489C0 38.1979 0.0822581 38.516 0.191935 38.6681C0.383871 38.9309 0.740322 38.9309 16.6847 38.9723C25.6371 38.9862 33.1089 38.9723 33.2734 38.9309C33.7395 38.8202 34 38.4745 34 37.9628C34 37.1606 33.6984 37.0638 31.2992 37.0638H29.2016L29.1742 18.7947L29.1331 0.525532L28.804 0.262766C28.5024 0.0138298 28.1048 0 17 0C5.89516 0 5.49758 0.0138298 5.19597 0.262766ZM27.2823 19.5V37.0638H17H6.71774V19.5V1.93617H17H27.2823V19.5Z" fill="#9B4575"/>
                <path d="M8.37662 18.8915C8.00646 19.3755 8.02017 19.7075 8.43146 20.1223C8.74679 20.4543 8.84275 20.4681 10.3919 20.4681C12.3113 20.4681 12.6678 20.316 12.6403 19.4862C12.6129 18.6702 12.2976 18.5319 10.3508 18.5319C8.67824 18.5319 8.65082 18.5319 8.37662 18.8915Z" fill="#9B4575"/>
            </g>
            <defs>
                <clipPath id="clip0_4_2">
                    <rect width="34" height="39" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    </>
  )
}
export default Door
