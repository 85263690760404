import React from 'react'
import config from '../../../config'
import Layout from '../../../layouts/Layout';

const Terms = () => {
	return (
		<Layout>
			<section className="terms">
				<h1> "TERMS" </h1>
				<div>
					<p>
						Please carefully read the following Terms of Use and Disclaimer before
						using the {config('APP_URL')} website. By using our website, you agree
						to these Terms of Use. If you do not agree to these Terms of Use, you
						may not use this site. THO reserves the right, at any time, to modify,
						alter or update these Terms of Use, and you agree to be bound by such
						modifications, alterations or updates. Individuals must be 18 years of
						age or older to use this Site and you warrant that you are 18 years of
						age or older.
					</p>
					<h2>COPYRIGHT</h2>
					<p>
						All content included in our website, such as text, graphics, logos,
						button icons, images, audio clips and software, is the property of THO
						its content suppliers and protected by U.S. and international
						copyright laws. The compilation (meaning the collection, arrangement
						and assembly) of all content on snapbac.com is the exclusive property
						of THO and protected by U.S. and international copyright laws. All
						software use on snapbac.com is the property of THO or its software
						suppliers and protected by U.S. and international copyright laws.
						Permission is granted to electronically copy and print hard copy
						portions of snapbac.com for the sole purpose of placing an order with{' '}
						{config('APP_URL')} or using the website as a shopping resource. Any
						other use, including the reproduction, modification, distribution,
						transmission, republication, display or performance, of the content of
						snapbac.com is strictly prohibited.
					</p>
					<h2>TRADEMARKS </h2>
					<p>
						All trademarks, logos, service marks and trade names are proprietary
						to THO or other respective owners that have granted{' '}
						{config('APP_URL')} the right and license to use such intellectual
						property.
					</p>
					<h2>DISCLAIMER </h2>
					<p>
						The information, services, products offered for sale and materials
						contained in and/or advertised on {config('APP_URL')}, including,
						without limitation, text, graphics and links, are provided on an "As
						Is" basis with no warranty.
						<span>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, {config('APP_URL')} AND ITS
            SUPPLIERS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR
            IMPLIED, WITH RESPECT TO SUCH INFORMATION, SERVICES, PRODUCTS AND
            MATERIALS, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, TITLE,
            NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS AND IMPLIED WARRANTIES
            ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE.
          </span>
						In addition, THO and its suppliers do not represent or warrant that
						the information accessible via {config('APP_URL')}
						is accurate, complete or current. We are not responsible for
						typographical errors. Price and availability information is subject to
						change without notice.
					</p>
					<h2>MODIFICATION AND DISCONTINUATION</h2>
					<p>
						We reserve the right at any time to modify, edit, delete, suspend or
						discontinue, temporarily or permanently the Service or our Website (or
						any portion thereof) with or without notice. You agree that we will
						not be liable to you or to any third party for any such modification,
						editing, deletion, suspension or discontinuance of the Service or our
						Website.
					</p>
					<h2>{config('APP_URL')} ELECTRONIC COMMUNICATIONS TO YOU</h2>
					<p>
						You agree that THO is authorized to transmit electronic mail to you
						for the purpose of informing you about your particular transaction,
						the {config('APP_URL')} site or THO products or services, or for such
						other lawful purpose(s) as THO deems appropriate.
					</p>
					<h2> LINKS TO OTHER WEB SITES</h2>
					<p>
						{config('APP_URL')} may contain links to websites other than our own.
						THO does not assume any responsibility for those sites and provides
						those links solely for the convenience of our visitors. snapbac.com
						does not control the content of these sites and takes no
						responsibility for their content, nor should it be implied that THO
						endorses or otherwise recommends such sites or the products or
						services offered.
					</p>
					<h2>LIMITATION OF LIABILITY</h2>
					<p>
          <span>
            IN NO EVENT SHALL ALAIS STUDIOS OR ANY OF ITS AFFILIATED ENTITIES OR
            SUPPLIERS BE LIABLE FOR ANY INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL,
            EXEMPLARY OR CONSEQUENTIAL DAMAGES, EVEN IF THO HAS BEEN PREVIOUSLY
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER IN AN ACTION,
            UNDER CONTRACT, NEGLIGENCE OR ANY OTHER THEORY, ARISING OUT OF OR IN
            CONNECTION WITH THE USE, INABILITY TO USE OR PERFORMANCE OF THE
            INFORMATION, SERVICES, PRODUCTS AND MATERIALS AVAILABLE FROM THE
            SITE.
          </span>
						These limitations shall apply notwithstanding any failure of essential
						purpose or the existence of any limited remedy. Because some
						jurisdictions do not allow limitations on how long an implied warranty
						lasts, or the exclusion or limitation of liability for consequential
						or incidental damages, the above limitations may not apply to you.
					</p>
					<h2>APPLICABLE LAW</h2>
					<p>
						The {config('APP_URL')} website is created and controlled by THO, LLC
						in the State of Tennessee, U.S. As such, the laws of the State of
						Tennessee will govern these disclaimers, terms and conditions without
						giving effect to any principles of conflict of law. We reserve the
						right to make changes to our website and these disclaimers, terms and
						conditions at any time.
					</p>
					<h2>INDEMNIFICATION </h2>
					<p>
						In using this Site and/or purchasing this Company’s Products, users
						expressly waive any and all claims that they may have against Company.
						You agree to indemnify, defend, and hold harmless Company and its
						affiliates, successors and assigns, together with all of their
						officers, directors, shareholders, members, employees, agents,
						licensors and suppliers, from and against all losses, expenses,
						damages and costs, including, without limitation, reasonable
						attorneys' fees, resulting from any violation of these Terms or any
						activity related to your account (including negligent or wrongful
						conduct) by you or any other person accessing the Site using your
						account. You agree to be solely responsible for payment of all sums
						owing to any person or entity by virtue of the exposition of the
						Materials and Information on the Site.
					</p>
					<h2>TERMINATION </h2>
					<p>
						Company may terminate these Terms at any time without notice, or
						suspend or terminate your access and use of the Site and/or Products
						at any time, with or without cause, at the Company’s sole discretion.
						In the event of termination, the intellectual property, disclaimers,
						and limitations of liability provisions set forth in these Terms will
						survive.
					</p>
					<h2>MISCELLANEOUS </h2>
					<p>
						If any of the provisions of these Terms are held by a court or other
						tribunal of competent jurisdiction to be void or unenforceable, such
						provisions shall be limited to the minimum extent.
					</p>
					<p>Last updated on January 1, 2021</p>
				</div>
			</section>
		</Layout>
	)
}

export default Terms
