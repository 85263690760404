const config = {
  APP_URL: 'https://www.thoagency.com/',
}

const get = key => {
  return config[key]
}

export { config }

export default get
