//Component to detect if the browser is Safari or not.
import React, {useEffect, useState} from 'react'

const SafariDetect = props => {
    const {
        children,
        className = ''
    } = props;

    const [safari, setSafari] = useState(false)

    useEffect(() => {
        let ua = navigator.userAgent.toLowerCase()
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                setSafari(false)
            } else {
                setSafari(true)
            }
        } else {
            setSafari(false)
        }
    }, [])

    return (
        <div className={`${className}${safari ? ' safari__detected' : ''}`}>
            {children}
        </div>
    )
}
export default SafariDetect
