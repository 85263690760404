import React, {useRef} from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = props => {
    const {
        id,
        children,
        pageClass = '',
        hideFooter
    } = props;

    const mainWrapper = useRef(null);

    return (
        <div className="page-wrapper" id={id}>
            <Header/>
            <main className={`main-content ${pageClass}`} ref={mainWrapper}>
                {children}
            </main>
            {!hideFooter && <Footer/>}
        </div>
    )
}

export default Layout;
