import React from 'react';
import {Link} from 'gatsby';

import FooterLinks from '../jsons/footer.json';
import Image from '../components/elements/Image';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__wrapper">
				<Image className="logo" src="/svgs/footer-logo.svg" alt="footerLogo"/>
				<div className="footer__grid">
					<div className="footer__grid--icons">
						{FooterLinks.footerSocials.map((item, index) => {
							return (
								<a href={item.link} target="_blank" rel="noreferrer"
								   key={`social-${index}`}>
									<Image src={item.icon} alt={item.alt}/>
								</a>
							)
						})}
					</div>
					<div className="footer__grid--links">
						{FooterLinks.footerContact.map((item, index) => {
							return (
								<div className="color__white" key={`contact-${index}`}>
									<a href={`mailto:${item.email}`}>{item.title}</a>
								</div>
							)
						})}
					</div>
					<div className="footer__navigation">
						<div className="footer__navigation--container">
							{FooterLinks.footerLinksMain.map((item, index) => {
								return (
									<Link to={item.link} key={`main-link-${index}`}>{item.title}</Link>
								)
							})}
						</div>

						<div className="footer__navigation--sub">
							{FooterLinks.footerLinksSub.map((item, index) => {
								return (
									<Link to={item.link} key={`sub-link-${index}`}>{item.title}</Link>
								)
							})}
						</div>
					</div>
				</div>
				<div className="footer__gif">
					<Image src="/images/footer-logo.gif" alt="menu" width={600} heigiht={239}/>
				</div>
				<div className="footer__copyright">
					<p>&copy; The House Of {new Date().getFullYear()}</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer;
