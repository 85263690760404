import React from 'react'

const Logo = () => {
  return (
    <>
        <svg width="84" height="29" viewBox="0 0 84 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.97149 2.51678C2.96634 2.55044 2.74509 3.9616 2.48267 5.65758C2.22025 7.35097 2.00156 8.75955 1.99642 8.78544C1.9887 8.81651 2.01185 8.8424 2.07875 8.87089C2.27685 8.95374 2.26913 8.95892 2.36432 8.69481C2.66019 7.87401 3.26992 6.84606 4.23213 5.55401C4.58202 5.08535 4.76726 4.87821 5.07084 4.62446C5.87611 3.94088 6.61706 3.67419 7.98833 3.57321C8.18643 3.55767 9.31844 3.53955 10.5045 3.52919L12.6579 3.51365V9.97133C12.6579 13.5238 12.645 17.1695 12.6321 18.0732C12.5498 23.345 12.5549 23.1637 12.4495 23.912C12.3517 24.6034 12.2925 24.7691 12.043 25.0513C11.7265 25.4138 11.32 25.7349 10.9393 25.9291C10.6254 26.087 9.82527 26.2916 9.15378 26.3848L8.92738 26.4159V26.5816V26.7473H14.2787H19.63V26.579C19.63 26.4858 19.6223 26.4107 19.6145 26.4107C19.6068 26.4107 19.4139 26.3693 19.1849 26.3201C18.6601 26.2062 17.9062 25.9938 17.6696 25.8929C17.2579 25.7168 16.8591 25.3879 16.6122 25.0229C16.3832 24.6785 16.3034 24.2875 16.2597 23.2777C16.2314 22.5915 16.2237 8.30901 16.252 5.35205L16.2674 3.52142H17.6593C21.328 3.52401 22.2439 3.60946 23.0955 4.02633C23.4222 4.18687 23.7901 4.45615 24.0937 4.75651C24.778 5.4349 25.9589 7.22928 26.5815 8.53687C26.6819 8.7466 26.7642 8.92267 26.7693 8.92526C26.7719 8.93044 26.8362 8.91231 26.9134 8.88383C26.9983 8.85535 27.0498 8.82169 27.0472 8.80097C27.0446 8.78026 26.8079 7.3458 26.5224 5.61097L26.0027 2.45981H14.4948H2.98435L2.97149 2.51678Z" fill="#F4F4EF"/>
            <path d="M69.2145 2.4883C65.7258 2.66955 62.497 4.10401 60.3797 6.40848C59.8394 6.99625 59.2451 7.81187 58.8489 8.5058C58.3318 9.41723 57.9073 10.4737 57.668 11.455C57.4107 12.5088 57.3104 13.3996 57.3104 14.6295C57.3104 15.4606 57.3335 15.8309 57.4262 16.5196C57.7478 18.8604 58.7769 21.0587 60.3822 22.8349L60.6524 23.1327L58.9184 24.8623L57.1843 26.592H57.6448H58.1054L59.6078 25.0798L61.1103 23.5677L61.4268 23.837C62.9678 25.142 64.8717 26.056 66.9299 26.4754C67.838 26.6619 68.5919 26.7318 69.6312 26.7292C71.7383 26.7266 73.5341 26.3175 75.3427 25.4268C78.0338 24.1011 80.0817 21.8613 81.2214 18.9924C82.1193 16.7268 82.2866 13.9744 81.6742 11.4602C81.188 9.46643 80.2567 7.67982 78.9497 6.24017L78.6796 5.94241L80.3441 4.26455L82.0061 2.58928H81.561H81.1134L79.6598 4.03669L78.2062 5.4841L77.9824 5.28991C76.75 4.21277 75.0803 3.32982 73.4518 2.88964C72.1937 2.55044 70.7067 2.41062 69.2145 2.4883ZM70.5266 3.07089C72.0908 3.24437 73.3489 3.88393 74.5992 5.13973C75.0443 5.58768 75.3993 6.02526 75.7492 6.55348C76.0116 6.95223 76.2561 7.37169 76.2561 7.42089C76.2561 7.44937 62.6668 21.1079 62.6231 21.1234C62.5974 21.1337 62.4199 20.7039 62.2552 20.2353C61.8436 19.0571 61.5657 17.6926 61.4319 16.183C61.3805 15.6082 61.3882 13.7284 61.4448 13.1536C61.5914 11.6518 61.841 10.3675 62.2089 9.23857C62.4713 8.4333 62.9318 7.43125 63.3486 6.75026C64.2516 5.28214 65.556 4.10402 66.9041 3.53437C67.9538 3.0916 69.235 2.92589 70.5266 3.07089ZM76.6574 8.20026C77.2877 9.60366 77.7122 11.2841 77.8897 13.0759C77.9463 13.6404 77.9669 14.8962 77.9309 15.4969C77.6994 19.285 76.3873 22.5216 74.3162 24.4221C73.2588 25.3905 72.2683 25.911 71.0463 26.1388C70.1278 26.3071 69.0061 26.289 68.0876 26.0871C66.8784 25.8229 65.8107 25.2507 64.8151 24.3341C64.1153 23.6894 63.3949 22.7365 62.9833 21.9105L62.9112 21.7629L69.7393 14.9065C73.4955 11.1365 76.5751 8.05268 76.5802 8.05268C76.5854 8.05268 76.6214 8.12 76.6574 8.20026Z" fill="#F4F4EF"/>
            <path d="M28.2024 2.64624L28.2101 2.80937L28.4391 2.84044C29.2084 2.95178 29.8824 3.17964 30.3172 3.4774C30.505 3.60428 30.8086 3.90464 30.9218 4.07294C31.1019 4.33964 31.2177 4.7824 31.3026 5.51776C31.3463 5.89062 31.3489 6.33857 31.3489 12.092C31.3489 19.417 31.3154 22.1021 31.2048 23.3942C31.1379 24.1865 31.0324 24.7691 30.9089 25.0254C30.7854 25.274 30.3995 25.6003 29.9467 25.8281C29.4862 26.0612 28.7658 26.2942 28.1921 26.3952L28.0944 26.4133V26.579V26.7473H32.9568H37.8193V26.5816V26.4159L37.6084 26.3848C37.1633 26.3201 36.5793 26.1388 36.1959 25.9446C35.6248 25.6546 35.2106 25.1963 35.0845 24.7147C34.9584 24.2383 34.9404 23.4175 34.9379 18.6221V14.733H41.4598H47.9817V18.2079C47.9791 22.5501 47.9559 23.6557 47.8376 24.5464C47.7835 24.9685 47.7372 25.1109 47.6086 25.2766C47.2947 25.6805 46.474 26.0819 45.589 26.2657C45.3163 26.3201 44.704 26.4107 44.5934 26.4107C44.5702 26.4107 44.5599 26.4599 44.5599 26.579V26.7473H49.6153H54.6708V26.5816V26.4133L54.5499 26.3978C54.025 26.3253 53.2764 26.1285 52.9316 25.9705C52.4556 25.753 51.9771 25.2688 51.8382 24.8623C51.689 24.4273 51.6658 23.9431 51.6324 20.7272C51.5989 17.167 51.6144 6.02526 51.6555 5.64464C51.7044 5.20446 51.7636 4.81348 51.8073 4.64776C51.9257 4.21017 52.2447 3.78294 52.6743 3.49035C53.0345 3.24178 53.7189 2.97508 54.2591 2.87151C54.4212 2.84044 54.5704 2.81196 54.5885 2.80678C54.609 2.8016 54.6193 2.74464 54.6193 2.64107V2.48571H49.6539H44.6885V2.64107C44.6885 2.81973 44.6731 2.80937 45.0101 2.83267C45.5118 2.86892 46.1936 3.02946 46.51 3.18482C46.7776 3.31946 47.0477 3.53696 47.2639 3.79589C47.7141 4.33446 47.8247 4.64517 47.8916 5.56696C47.9688 6.64669 47.9817 7.28365 47.9817 10.6497V14.0857L41.4675 14.0805L34.9507 14.0728L34.943 12.2732C34.9353 10.3183 34.9764 6.25053 35.015 5.56696C35.0613 4.71508 35.2363 4.24901 35.6994 3.75705C36.1239 3.30133 36.6873 3.05017 37.6907 2.86374L38.0123 2.80419L38.02 2.64624L38.0277 2.48571H33.1112H28.1947L28.2024 2.64624Z" fill="#F4F4EF"/>
        </svg>
    </>
  )
}

export default Logo
